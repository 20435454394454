<template>
	<div>
		<FilterChips
			v-show="!showFilter"
			:filters="filters"
			:showFilter="showFilter"
			@resetFilter="resetFilter"
		/>

		<div class="filter-content" :class="{'show': showFilter}">
      <v-card class="pa-5 base-card" :class="{'show': showFilter}">
        <v-row>
          <v-col cols="4">
            <v-text-field
              v-model="filters.fullName"
              class="mt-0"
              label="ФИО"
              hide-details
              clearable
              @input="input"
            />
          </v-col>

          <v-col cols="4">
            <v-text-field
              v-model="filters.snils"
              class="mt-0"
              label="Номер СНИЛС"
              v-mask="'###-###-### ##'"
              hide-details
              clearable
              @input="input"
            />
          </v-col>

          <v-col cols="4">
            <v-autocomplete
              v-model="filters.cycle"
              class="mt-0"
              label="Номер цикла"
              placeholder="Выберите из списка"
              :items="cyclesList"
              item-text="cycleNum"
              hide-no-data
              hide-details
              clearable
              return-object
              @change="input"
            />
          </v-col>
        </v-row>
      </v-card>
		</div>
	</div>
</template>

<script>
import FilterChips from '@/components/listeners/FilterChips'

export default {
	name: 'ListenersFilter',

	components: {
    FilterChips
	},

	props: {
		showFilter: Boolean,
    cyclesList: Array,
    cycle: Object
	},

  created() {
    this.setDefaultFilters()
  },

  data: () => ({
    filters: {
      fullName: null,
      snils: null,
      cycle: null
    }
	}),

	methods: {
    setDefaultFilters() {
      if (this.cycle) {
        this.filters.cycle = this.cycle || null
        this.setFiltersToLocalStorage()
      } else {
        this.getFiltersFromLocalStorage()
      }
    },

    getFiltersFromLocalStorage() {
      const filters = localStorage.getItem('listenersFilters')

      if (filters) {
        this.filters = JSON.parse(filters)
      }
    },

    setFiltersToLocalStorage() {
      // сохраняем в LocalStorage только цикл
      const filters = {
        fullName: null,
        snils: null,
        cycle: this.filters.cycle
      }

      localStorage.setItem('listenersFilters', JSON.stringify(filters))
    },

		input() {
      this.filters.fullName = this.filters.fullName || null
      this.filters.snils = this.filters.snils || null
      this.filters.cycle = this.filters.cycle || null

      this.setFiltersToLocalStorage()
			this.$emit('setFilters', { ...this.filters })
		},

		resetFilter(filters) {
			this.filters = { ...filters }
			this.input()
		}
	}
}
</script>