<template>
  <div class="d-flex justify-space-between pt-5">
    <div>
      <span
        :title="disabledTitle"
        :class="{ 'cursor-help': !cyclePlanDistFromFilters }"
      >
        <v-btn
          :disabled="!cyclePlanDistFromFilters"
          small
          color="primary"
          class="white--text px-4 mr-3"
          @click="$router.push('/listeners/create')"
        >
          <v-icon left size="18">mdi-account-plus-outline</v-icon>Создать
        </v-btn>

        <v-btn
          :disabled="!cyclePlanDistFromFilters"
          small
          color="primary"
          class="white--text px-4 mr-5"
          @click="$emit('openAddListenerDialog')"
        >
          <v-icon left size="18">mdi-account-check-outline</v-icon>Добавить
        </v-btn>
      </span>


      <v-menu offset-y v-model="showMenu" v-if="cycleIdFromFilters">
        <template #activator="{ on }">
          <v-btn
            v-on="on"
            text
            color="accent"
            class="print-btn px-0"
            title="Печать"
            :ripple="false"
          >
            <v-icon left class="mr-0" size="20">mdi-printer-outline</v-icon>
            <span class="ml-2">Печать</span>
            <v-icon right class="ml-0">{{ showMenu ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item dense target="_blank" :to="reportApi.dpoListenerCardCommon()">
            <v-list-item-title class="list-text">
              Бланк личной карточки
            </v-list-item-title>
          </v-list-item>

          <v-list-item dense target="_blank" :to="reportApi.dpoListenerCardByCycle(cycleIdFromFilters)">
            <v-list-item-title class="list-text">
              Личные карточки для всех
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-btn
        v-else
        text
        color="accent"
        class="print-btn px-0"
        title="Бланк личной карточки"
        target="_blank"
        :to="reportApi.dpoListenerCardCommon()"
      >
        <v-icon left class="mr-0" size="20">mdi-printer-outline</v-icon>
        <span class="ml-2">Бланк личной карточки</span>
      </v-btn>
    </div>

    <v-btn
      text
      color="accent"
      :ripple="false"
      class="show-filter px-0 ml-4"
      @click="$emit('setShowFilter')"
    >
      <v-icon left size="20">mdi-filter-outline</v-icon>Фильтры
      <v-icon left size="20" class="ml-1 mr-0">{{ showFilter ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { reportApi } from '@/api'

export default {
  name: 'ListenerButtons.vue',

  props: {
    showFilter: Boolean,
    cycleIdFromFilters: Number,
    cyclePlanDistFromFilters: Boolean
  },

  data: () => ({
    reportApi,
    showMenu: false
  }),

  computed: {
    disabledTitle() {
      return !this.cyclePlanDistFromFilters
        ? 'Для добавления слушателя на цикл необходимо распределить часы в плановом расписании цикла согласно программе'
        : ''
    }
  }
}
</script>