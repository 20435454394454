<template>
  <div class="tab-content">
    <p>
      <strong>Дата начала обучения: </strong>
      {{ data.addEduBeginDate || '-' }}
    </p>

    <p>
      <strong>Дата окончания обучения: </strong>
      {{ data.addEduEndDate || '-' }}
    </p>

    <p>
      <strong>Учреждение: </strong>
      {{ data.addEduSchool ? data.addEduSchool.shortName : '-' }}
    </p>

    <p>
      <strong>Форма обучения: </strong>
      {{ data.addEduForm ? data.addEduForm.name : '-' }}
    </p>

    <p>
      <strong>Количество часов: </strong>
      {{ data.addEduCountHours || '-' }}
    </p>

    <p>
      <strong>Место прохождения: </strong>
      {{ data.addEduPlace || '-' }}
    </p>

    <p>
      <strong>Документ: </strong>
      {{ data.addEduCardType ? data.addEduCardType.description : '-' }}
    </p>

    <p>
      <strong>Серия: </strong>
      {{ data.addEduCardSeries || '-' }}
    </p>

    <p>
      <strong>Номер: </strong>
      {{ data.addEduCardNumber || '-' }}
    </p>

    <p>
      <strong>Дата выдачи: </strong>
      {{ data.addEduCardIssueDate || '-' }}
    </p>


    <p>
      <strong>Специалитет: </strong>
      {{ data.addEduSpecialitySpc ? data.addEduSpecialitySpc.description : '-' }}
    </p>

    <p>
      <strong>Интернатура: </strong>
      {{ data.addEduSpecialityInt ? data.addEduSpecialityInt.description : '-' }}
    </p>

    <p>
      <strong>Ординатура: </strong>
      {{ data.addEduSpecialityOrd ? data.addEduSpecialityOrd.description : '-' }}
    </p>

    <p>
      <strong>Аспирантура: </strong>
      {{ data.addEduSpecialityIdAsp ? data.addEduSpecialityIdAsp.description : '-' }}
    </p>

    <p>
      <strong>Дополнительное профессиональное образование: </strong>
      {{ data.addEduSpecialityDpo ? data.addEduSpecialityDpo.specialtyName : '-' }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'ShowAddEducationCard',

  props: {
    data: Object
  }
}
</script>

<style scoped>
.tab-content h2 {
  font-weight: 500;
  line-height: 1.1 !important;
  font-size: 19px !important;
  margin-top: 19px;
  margin-bottom: 19px;
  text-align: left;
  color: #37474f
}

.tab-content p {
  margin-bottom: 4px;
  font-size: 15px;
  color: #37474f
}
</style>