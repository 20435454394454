<template>
	<v-row class="my-0">
		<v-col cols="12" class="py-0">
			<div
        class="text-right filter-chips"
        :class="{'pt-1': isFilters}"
      >
				<v-chip
					v-for="(chip, i) in filterChipsComputed"
					:key="i"
					v-if="chip.visible"
					class="cursor-help ml-1"
					outlined
					text-color="primary"
					close
					small
					:title="chip.name"
          close-icon="mdi-close"
					@click:close="resetFilter(chip.prop)"
				>
					{{ chip.value }}
				</v-chip>
			</div>
		</v-col>
	</v-row>
</template>

<script>
export default {
	name: "ListenersFilterChips",

	props: {
    filters: Object,
    showFilter: Boolean
	},

  data: () => ({
    filterChips: [
      { prop: 'fullName', name: 'ФИО', visible: false },
      { prop: 'snils', name: 'Номер СНИЛС', visible: false },
      { prop: 'cycle', name: 'Номер цикла', visible: false }
    ]
	}),

	computed: {
    isFilters() {
      return Object.keys(this.filters).some(key => !!this.filters[key])
    },

		filterChipsComputed() {
      return this.filterChips.map(chip => {
        let value = null

        switch (chip.prop) {
          case 'cycle':
            value = this.filters[chip.prop]?.cycleNum || null
            break
          default:
            value = this.filters[chip.prop]
        }

        return this.filters[chip.prop] == null ? chip : { ...chip, value, visible: true }
      })
		}
	},

	methods: {
		resetFilter(prop) {
			this.$emit('resetFilter', { ...this.filters, [prop]: null })
		}
	}
}
</script>