<template>
  <div class="tab-content">
    <p>
      <strong>Источник финансирования: </strong>
      {{ data.environment ? data.environment.name : '-' }}
    </p>

    <p>
      <strong>№ договора: </strong>
      {{ data.contractNum || '-' }}
    </p>

    <p>
      <strong>Дата выдачи: </strong>
      {{ data.contractDate || '-' }}
    </p>

    <p>
      <strong>Дата зачисления: </strong>
      {{ data.enrollmentDate || '-' }}
    </p>

    <p>
      <strong>Статус зачисления: </strong>
      {{ data.enrollmentState ? 'Да' : 'Нет' }}
    </p>

    <p>
      <strong>Проживает во время обучения в общежитии: </strong>
      {{ data.isHotel ? 'Да' : 'Нет' }}
    </p>

    <p>
      <strong>Номер слушателя на программе ПП (ПК): </strong>
      {{ data.listenerNumber || '-' }}
    </p>

    <p>
      <strong>№ НМО: </strong>
      {{ data.nmo || '-' }}
    </p>

    <p>
      <strong>Профессиональная переподготовка: </strong>
      {{ data.retraining ? 'Да' : 'Нет' }}
    </p>

    <p>
      <strong>
        {{ data.retraining ? 'Диплом о профессиональной переподготовке' : 'Удостоверение о повышении квалификации' }}:
      </strong>
      {{ data.cycleDocumentType ? data.cycleDocumentType.description : '-' }}
    </p>

    <p>
      <strong>Свидетельство об аккредитации: </strong>
      {{ data.isValidAccred ? 'Да' : 'Нет' }}
    </p>

    <div v-if="data.isValidAccred">
      <p>
        <strong>Государственный номер свидетельства: </strong>
        {{ data.validAccredGosNum || '-' }}
      </p>

      <p>
        <strong>Регистрационный номер свидетельства: </strong>
        {{ data.validAccredRegNum || '-' }}
      </p>

      <p>
        <strong>Специальность: </strong>
        {{ data.specialityTable ? data.specialityTable.specialtyName : '-' }}
      </p>
    </div>

    <div v-if="cardDocs.length">
      <h2>Удостоверение о повышении квалификации</h2>

      <div v-for="doc in cardDocs" :key="doc.id">
        <p>
          <strong>Тип документа: </strong>
          <span>{{ doc.qualificationCardType }}</span>
          <span v-if="doc.isDublicate"> (дубликат)</span>
        </p>

        <p>
          <strong>Серия: </strong><span>{{ doc.qualificationCardSeries }}</span><br>
          <strong>Номер: </strong><span>{{ doc.qualificationCardNum }}</span><br>
          <strong>Гос.номер: </strong><span>{{ doc.qualificationCardGosNum }}</span><br>
          <strong>Дата: </strong><span>{{ doc.docIssueDate }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShowTrainCard',

  props: {
    data: Object,
    cardDocs: Array
  }
}
</script>

<style scoped>
.tab-content h2 {
  font-weight: 500;
  line-height: 1.1 !important;
  font-size: 19px !important;
  margin-top: 19px;
  margin-bottom: 19px;
  text-align: left;
  color: #37474f
}

.tab-content p {
  margin-bottom: 4px;
  font-size: 15px;
  color: #37474f
}
</style>