<template>
  <div class="tab-content">
    <h2>Образование</h2>

    <p>
      <strong>Уровень образования: </strong>
      {{ data.eduLevel ? data.eduLevel.name : '-' }}
    </p>

    <p>
      <strong>Учебное заведение: </strong>
      <span v-if="!data.eduSchoolCheck">{{ data.eduSchool ? data.eduSchool.shortName : '-' }}</span>
      <span v-else>{{ data.eduSchoolOutList || '-' }}</span>
    </p>

    <p>
      <strong>Год окончания: </strong>
      {{ data.eduYear ? data.eduYear.name : '-' }}
    </p>

    <h2>Документ об образовании</h2>

    <p>
      <strong>Тип документа: </strong>
      {{ data.eduCardType ? data.eduCardType.description : '-' }}
    </p>

    <p>
      <strong>Серия: </strong>
      {{ data.eduCardSeries || '-' }}
    </p>

    <p>
      <strong>Номер: </strong>
      {{ data.eduCardNumber || '-' }}
    </p>

    <p>
      <strong>Кем выдано: </strong>
      <span v-if="!data.eduFirstSpecCenterCheck">
        {{ data.eduFirstSpecCenter ? data.eduFirstSpecCenter.shortName : '-' }}
      </span>
      <span v-else>{{ data.eduFirstSpecCenterOutList || '-' }}</span>
    </p>

    <p>
      <strong>Дата выдачи документа: </strong>
      {{ data.eduCardIssueDate || '-' }}
    </p>

    <h2>Специальность по диплому</h2>

    <p>
      <strong>Специалитет: </strong>
      {{ data.eduSpecialtySpc ? data.eduSpecialtySpc.description : '-' }}
    </p>

    <p>
      <strong>Интернатура: </strong>
      {{ data.eduSpecialtyInt ? data.eduSpecialtyInt.description : '-' }}
    </p>

    <p>
      <strong>Ординатура: </strong>
      {{ data.eduSpecialtyOrd ? data.eduSpecialtyOrd.description : '-' }}
    </p>

    <p>
      <strong>Аспирантура: </strong>
      {{ data.eduSpecialtyAsp ? data.eduSpecialtyAsp.description : '-' }}
    </p>

    <p>
      <strong>Дополнительное профессиональное образование: </strong>
      {{ data.eduSpecialtyDpo ? data.eduSpecialtyDpo.specialtyName : '-' }}
    </p>

    <h2>Научная квалификация</h2>

    <p>
      <strong>Степень: </strong>
      {{ data.eduAcademicDegree ? data.eduAcademicDegree.name : '-' }}
    </p>

    <p>
      <strong>Номер документа (степень): </strong>
      {{ data.eduAcademicDocNumber || '-' }}
    </p>

    <p>
      <strong>Дата выдачи (степень): </strong>
      {{ data.eduAcademicDocDate || '-' }}
    </p>

    <p>
      <strong>Звание: </strong>
      {{ data.eduAcademicCategory ? data.eduAcademicCategory.name : '-' }}
    </p>

    <p>
      <strong>Квалификационная категория: </strong>
      {{ data.eduQualificationCategory ? data.eduQualificationCategory.name : '-' }}
    </p>

    <h2>Индивидуальный номер мед. работника</h2>

    <p>
      <strong>Номер мед. работника: </strong>
      {{ data.indNumOfHealthWorker || '-' }}
    </p>

    <p>
      <strong>Дата включения в реестр: </strong>
      {{ data.dateInclude2Register || '-' }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'ShowEducationCard',

  props: {
    data: Object
  }
}
</script>

<style scoped>
.tab-content h2 {
  font-weight: 500;
  line-height: 1.1 !important;
  font-size: 19px !important;
  margin-top: 19px;
  margin-bottom: 19px;
  text-align: left;
  color: #37474f
}

.tab-content h2:first-child {
  margin-top: 0
}

.tab-content p {
  margin-bottom: 4px;
  font-size: 15px;
  color: #37474f
}
</style>