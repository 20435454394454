<template>
  <v-row justify="center">
    <v-dialog :value="dialog" max-width="850" @input="closeDialog">
      <v-card class="dialog-card">
        <v-card-title class="headline accent--text pb-4">
          Добавление слушателя
        </v-card-title>

        <v-card-text outlined tile class="border-top px-2">
          <v-card class="mt-4 elevation-0">
            <v-card-title class="pt-1 pb-0">
              <v-text-field
                v-model="filters.fullName"
                class="mt-0 pt-0 pr-3 search-field"
                append-icon="mdi-magnify"
                label="Поиск по ФИО"
                single-line
                clearable
                :error-messages="errorMessages.fullName"
                @input="$v.filters.fullName.$touch(); setFilters()"
                @blur="$v.filters.fullName.$touch()"
              />

              <v-text-field
                v-model="filters.snils"
                class="mt-0 pt-0 pl-3 search-field"
                append-icon="mdi-magnify"
                label="Поиск по СНИЛС"
                v-mask="'###-###-### ##'"
                single-line
                clearable
                :error-messages="errorMessages.snils"
                @input="$v.filters.snils.$touch(); setFilters()"
                @blur="$v.filters.snils.$touch()"
              />
            </v-card-title>

            <v-data-table
              v-scroll.self="tableScroll"
              :loading="tableLoading"
              class="base-table"
              loading-text="Поиск слушателей..."
              fixed-header
              hide-default-footer
              :headers="headers"
              :items="listenersList"
              :items-per-page="-1"
            >
              <template #item="{ item, index }">
                <tr @click="rowClick(item)" :class="{ 'selectedRow': item === selectedItem }" :key="index">
                  <td>{{ getFullName(item.lastName, item.firstName, item.middleName) || '-' }}</td>
                  <td>{{ item.birthDate || '-' }}</td>
                  <td>{{ item.snils || '-' }}</td>
                  <td>{{ item.state && item.state.name || '-' }}</td>
                </tr>
              </template>

              <template slot="no-data">
                <div class="py-3">Не указаны параметры поиска</div>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>

        <v-card-actions class="px-6">
          <v-spacer></v-spacer>

          <v-btn
            v-if="selectedItem"
            color="success"
            text
            @click="addListener"
          >
            Добавить
          </v-btn>

          <v-btn text color="accent" @click="closeDialog">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { fileDpoCardApi } from '@/api'
import _debounce from 'lodash/debounce'
import { snilsFormat, checkSnilsSum, setValidateMessages } from '@/scripts/validation'
import { minLength } from 'vuelidate/lib/validators'
import { getFullName } from '@/scripts'

export default {
  name: 'AddListenerDialog',

	props: {
		dialog: Boolean
	},

	data: () => ({
    tableLoading: false,

    pageNumber: 0,
    lastPage: false,
    total: 0,

    filters: {
      fullName: null,
      snils: null
    },
    headers: [
      { text: 'ФИО', value: 'fullName', sortable: false, align: 'left' },
      { text: 'Дата рождения', value: 'birthDate', sortable: false, align: 'left'},
      { text: 'Номер СНИЛС', value: 'snils', sortable: false, align: 'left' },
      { text: 'Регион', value: 'state', sortable: false, align: 'left' }
    ],
    listenersList: [],
    selectedItem: null,

    getFullName
	}),

  validations() {
    return {
      filters: {
        fullName: { minLength: minLength(3) },
        snils: { snilsFormat, checkSnilsSum }
      }
    }
  },

	computed: {
    errorMessages() {
      const allErrors = {}

      Object.keys(this.$v.filters.$params).forEach(key => {
        allErrors[key] = setValidateMessages(this.$v.filters[key])
      })

      return allErrors
    }
	},

	methods: {
    async searchListeners() {
      try {
        if (!this.lastPage) {
          this.tableLoading = true
          const { content, last, totalElements } = await fileDpoCardApi.searchListenersByParams(this.pageNumber, this.filters)
          if (this.pageNumber === 0) this.listenersList = []
          this.lastPage = last
          this.total = totalElements
          this.pageNumber++
          content?.length && content.forEach(obj => this.listenersList.push(obj))
        }
      } catch (e) {
        console.log(e.status)
      } finally {
        this.tableLoading = false
      }
    },

    tableScroll(event) {
      const el = event.target
      if (el.scrollHeight - el.clientHeight - el.scrollTop <= 10 && !this.tableLoading) {
        this.searchListeners()
      }
    },

    rowClick(item) {
      this.selectedItem = this.selectedItem === item ? null : item
    },

    addListener() {
      this.$router.push({ name: 'addListener', params: { addItem: this.selectedItem } })
    },

    setFilters: _debounce(async function() {
      this.pageNumber = 0
      this.lastPage = false
      this.total = 0

      this.filters.fullName = this.filters.fullName || null
      this.filters.snils = this.filters.snils || null

      if (!this.$v.$invalid && (this.filters.fullName || this.filters.snils)) {
        await this.searchListeners()
      }
    }, 500),

    resetModal() {
      this.listenersList = []
      this.filters = {
        fullName: null,
        snils: null
      }
    },

    closeDialog() {
      this.resetModal()
			this.$emit('closeDialog')
		}
	}
}
</script>

<style scoped>
.base-table {
  min-height: 320px;
  max-height: 320px;
}

.base-table >>> tbody tr:hover td {
  background-color: var(--selectedRow-color) !important;
  cursor: pointer;
}

.search-field {
  width: 50%;
}

.search-field >>> .v-label.theme--light {
  font-weight: 400;
}

.search-field >>> input[type=text] {
  font-weight: 400;
}
</style>