<template>
  <v-menu offset-y v-model="showMenu">
    <template #activator="{ on }">
      <v-btn
        v-on="on"
        text
        color="btnIcon"
        class="print-btn px-0"
        title="Печать"
        :ripple="false"
      >
        <v-icon left class="mr-0" size="20">mdi-printer-outline</v-icon>
        <v-icon right class="ml-0">{{ showMenu ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </v-btn>
    </template>

    <v-list>
      <v-list-item dense target="_blank" :to="reportApi.dpoFinalAttestationProtocol(fileDpoCardId)">
        <v-list-item-title class="list-text">
          Протокол итоговой аттестации
        </v-list-item-title>
      </v-list-item>

      <v-list-item dense target="_blank" :to="reportApi.dpoListenerCard(fileDpoCardId)">
        <v-list-item-title class="list-text">
          Персональный бланк личной карточки
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { reportApi } from '@/api'

export default {
  name: "ListenerReportMenu",

  props: {
    fileDpoCardId: Number
  },

  data: () => ({
    reportApi,
    showMenu: false,
    dialog: false
  })
}
</script>

<style scoped>
.menu-btn {
  min-width: 46px !important;
  height: 28px !important
}

.menu-btn::before {
  background-color: transparent !important
}
</style>