<template>
  <v-row justify="center">
    <v-dialog :value="dialog" max-width="700" persistent @input="closeDialog">
      <div class="control-buttons" ref="controlButtons">
        <v-btn
          v-show="tab !== 0"
          class="control-button prev-tab"
          :ripple="false"
          icon
          color="grey"
          title="Предыдущий таб"
          @click="tab--"
        >
          <v-icon size="40">mdi-chevron-left</v-icon>
        </v-btn>

        <v-btn
          v-show="tab !== tabsComputed.length - 1"
          class="control-button next-tab"
          :ripple="false"
          icon
          color="grey"
          title="Следующий таб"
          @click="tab++"
        >
          <v-icon size="40">mdi-chevron-right</v-icon>
        </v-btn>
      </div>

      <v-card>
        <v-card-title class="card-title headline accent--text pb-4">
          {{ tabsComputed[tab] }}
        </v-card-title>

        <v-card-text outlined tile class="border-top">
          <v-tabs-items class="px-4" v-model="tab">
            <v-tab-item v-for="(item, i) in tabsComputed" :key="i">
              <v-container>
                <v-card class="card" elevation="0">
                  <component
                    :is="currentComponent.component"
                    :key="tab"
                    :data="listenerCard[currentComponent.dataProp]"
                    :cardDocs="cardDocs"
                  />
                </v-card>
              </v-container>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>

        <div class="d-flex">
          <v-spacer></v-spacer>
          <span class="total-slides-info">{{ tab + 1 }} / {{ tabsComputed.length }}</span>
        </div>

        <v-card-actions class="px-6 pt-1">
          <v-spacer></v-spacer>
          <v-btn text color="accent" @click="closeDialog">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { fileDpoCardApi } from '@/api'
import { ListenerCard } from '@/models'
import PersonalDataCard from '@/components/listeners/listener/showCard/PersonalData'
import EducationCard from '@/components/listeners/listener/showCard/Education'
import WorkCard from '@/components/listeners/listener/showCard/Work'
import TrainCard from '@/components/listeners/listener/showCard/Train'
import AddEducationCard from '@/components/listeners/listener/showCard/AddEducation'

export default {
  name: 'ShowListenerDialog',

  components: {
    PersonalDataCard,
    EducationCard,
    WorkCard,
    TrainCard,
    AddEducationCard
  },

  props: {
    dialog: Boolean,
    listenerId: Number
  },

  data: () => ({
    tab: 0,
    tabs: ['Основная', 'Образование', 'Место работы', 'Текущее повышение квалификации', 'Доп. образование'],
    componentsList: [
      { component: PersonalDataCard, dataProp: 'personalData' },
      { component: EducationCard, dataProp: 'education' },
      { component: WorkCard, dataProp: 'work' },
      { component: TrainCard, dataProp: 'train' },
      { component: AddEducationCard, dataProp: 'addEducation' }
    ],
    cardDocs: [],

    listenerCard: new ListenerCard()
  }),

  computed: {
    currentComponent() {
      return this.componentsList.find((el, i) => this.tab === i)
    },

    tabsComputed() {
      const isShowFifthTab = this.listenerCard.train.environment?.id === 4
      return isShowFifthTab ? this.tabs : this.tabs.slice(0, -1)
    }
  },

  methods: {
    async getListenerCard() {
      const data = await fileDpoCardApi.getListenerCard(this.listenerId)
      data && this.listenerCard.buildFromAPI(data)

      await this.getCardDocs()
    },

    async getCardDocs() {
      this.cardDocs = await fileDpoCardApi.getCardDocs(this.listenerId)
    },

    closeDialog() {
      this.tab = 0
      this.listenerCard = new ListenerCard()
      this.$emit('closeDialog')
    }
  },

  watch: {
    listenerId(val) {
      val && this.getListenerCard()
    }
  }
}
</script>

<style scoped>
.border-top {
  border: 0 !important;
  border-top: 1px solid rgba(0, 0, 0, 0.12) !important;
  padding-bottom: 0 !important;
}

.card-title {
  padding-left: 52px !important
}

.card {
  overflow-y: auto;
  min-height: 400px;
  max-height: 400px;
}

.control-buttons {
  position: relative;
}

.control-button {
  position: absolute;
  top: 246px;
  z-index: 1;
}

.control-button::before {
  background-color: transparent !important
}

.prev-tab {
  left: 5px;
}

.next-tab {
  right: 5px;
}

.total-slides-info {
  font-size: 12px;
  color: var(--accent-color);
  line-height: 1;
  padding-right: 34px;
}
</style>