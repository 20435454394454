<template>
  <div class="tab-content">
    <h2>Место работы</h2>

    <p>
      <strong>Страна: </strong>
      {{ data.workCountry ? data.workCountry.name : '-' }}
    </p>

    <p v-if="data.isRusWorkCountry">
      <strong>Регион: </strong>
      {{ data.workState ? data.workState.name : '-' }}
    </p>

    <p v-if="data.workState">
      <strong>Город: </strong>
      <span v-if="!data.workTownCheck">{{ data.workTown ? data.workTown.name : '-' }}</span>
      <span v-else>{{ data.workTownOutList || '-' }}</span>
    </p>

    <p>
      <strong>Место работы: </strong>
      {{ data.workPlaceOutList || '-' }}
    </p>

    <p>
      <strong>Адрес места работы: </strong>
      {{ data.workPlaceAddress || '-' }}
    </p>

    <p>
      <strong>Ведомственная принадлежность места работы: </strong>
      {{ data.workEnvironment ? data.workEnvironment.name : '-' }}
    </p>

    <p>
      <strong>Работник СЗГМУ: </strong>
      {{ data.workIsSZGMUEmployee ? 'Да' : 'Нет' }}
    </p>

    <p>
      <strong>Незанятое лицо по направлению службы занятости: </strong>
      {{ data.workIsEmploymentService ? 'Да' : 'Нет' }}
    </p>

    <h2>Общий стаж работы</h2>

    <p>
      <strong>Количество лет: </strong>
      {{ data.workLengthTotalYear || '-' }}
    </p>

    <p>
      <strong>Количество месяцев: </strong>
      {{ data.workLengthTotalMonth || '-' }}
    </p>

    <h2>Стаж работы по специальности обучения</h2>

    <p>
      <strong>Количество лет: </strong>
      {{ data.workLengthYear || '-' }}
    </p>

    <p>
      <strong>Количество месяцев: </strong>
      {{ data.workLengthMonth || '-' }}
    </p>

    <h2>Должность</h2>

    <p>
      <strong>Категория должности: </strong>
      {{ data.workCategory ? data.workCategory.categoryDescription : '-' }}
    </p>

    <p>
      <strong>Должность: </strong>
      <span v-if="!data.workTitleCheck">{{ data.workTitle ? data.workTitle.title : '-' }}</span>
      <span v-else>{{ data.workTitleOutList || '-' }}</span>
    </p>

    <p>
      <strong>Должность на момент обучения по записи в трудовой книжке: </strong>
      {{ data.workPosition || '-' }}
    </p>

    <p>
      <strong>Руководитель структурного подразделения: </strong>
      {{ data.workIsManager ? 'Да' : 'Нет' }}
    </p>

    <p>
      <strong>Государственный служащий: </strong>
      {{ data.workIsGoverEmpl ? 'Да' : 'Нет' }}
    </p>

    <p>
      <strong>Уволен с военной службы: </strong>
      {{ data.workMilitaryServDismiss ? 'Да' : 'Нет' }}
    </p>

    <p v-if="data.workMilitaryServDismiss">
      <strong>В этом году: </strong>
      {{ data.workMilitaryServDismissThisYear ? 'Да' : 'Нет' }}
    </p>

    <p>
      <strong>Является преподавателем: </strong>
      {{ data.workIsTeacher ? 'Да' : 'Нет' }}
    </p>

    <p v-if="data.workIsTeacher">
      <strong>Какого образовательного учреждения: </strong>
      {{ data.workEduInstitution ? data.workEduInstitution.name : '-' }}
    </p>

    <h2>Принадлежность врача к профессиональной группе</h2>

    <p>
      <strong>Врачи, провизоры сельской местности: </strong>
      {{ data.workIsRuralAreaDoctor ? 'Да' : 'Нет' }}
    </p>

    <p>
      <strong>Врачи скорой помощи: </strong>
      {{ data.workIsEmergencyDoctor ? 'Да' : 'Нет' }}
    </p>

    <p>
      <strong>Врачи участковые города: </strong>
      {{ data.workIsDistrictDoctor ? 'Да' : 'Нет' }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'ShowWorkCard',

  props: {
    data: Object
  }
}
</script>

<style scoped>
.tab-content h2 {
  font-weight: 500;
  line-height: 1.1 !important;
  font-size: 19px !important;
  margin-top: 19px;
  margin-bottom: 19px;
  text-align: left;
  color: #37474f
}

.tab-content h2:first-child {
  margin-top: 0
}

.tab-content p {
  margin-bottom: 4px;
  font-size: 15px;
  color: #37474f
}
</style>