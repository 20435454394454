<template>
  <div class="tab-content">
    <h2>Личные данные</h2>

    <p>
      <strong>Гражданство: </strong>
      {{ data.nationality ? data.nationality.name : '-' }}
    </p>

    <p>
      <strong>Номер цикла: </strong>
      {{ data.cycleNum || '-' }}
    </p>

    <p>
      <strong>Фамилия: </strong>
      {{ data.lastName || '-' }}
    </p>

    <p>
      <strong>Имя: </strong>
      {{ data.firstName || '-' }}
    </p>

    <p>
      <strong>Отчество: </strong>
      {{ data.middleName || '-' }}
    </p>

    <p>
      <strong>Фамилия в родительном падеже: </strong>
      {{ data.lastNameGen || '-' }}
    </p>

    <p>
      <strong>Имя в родительном падеже: </strong>
      {{ data.firstNameGen || '-' }}
    </p>

    <p>
      <strong>Отчество в родительном падеже: </strong>
      {{ data.middleNameGen || '-' }}
    </p>

    <p>
      <strong>Пол: </strong>
      {{ data.gender ? data.gender.name : '-' }}
    </p>

    <p>
      <strong>СНИЛС: </strong>
      {{ data.snils || '-' }}
    </p>

    <p>
      <strong>Дата рождения: </strong>
      {{ data.birthDate || '-' }}
    </p>

    <h2>Паспортные данные</h2>

    <p>
      <strong>Документ, удостоверяющий личность: </strong>
      {{ data.identityCardType ? data.identityCardType.identityCardNameFull : '-' }}
    </p>

    <p v-if="data.isMigrationIdentityCard">
      <strong>Идентификатор УФМС: </strong>
      {{ data.migrationServiceIdentifier || '-' }}
    </p>

    <p>
      <strong>Серия: </strong>
      {{ data.identityCardSeries || '-' }}
    </p>

    <p>
      <strong>Номер: </strong>
      {{ data.identityCardNumber || '-' }}
    </p>

    <p>
      <strong>Дата выдачи: </strong>
      {{ data.identityCardIssueDate || '-' }}
    </p>

    <p>
      <strong>Кем выдан: </strong>
      {{ data.identityCardIssueBy || '-' }}
    </p>

    <h2>Адрес по прописке</h2>

    <p>
      <strong>Индекс: </strong>
      {{ data.zipCode || '-' }}
    </p>

    <p>
      <strong>Страна: </strong>
      {{ data.countryRegion ? data.countryRegion.name : '-' }}
    </p>

    <p v-if="data.isRusCountryRegion">
      <strong>Регион: </strong>
      {{ data.state ? data.state.name : '-' }}
    </p>

    <p>
      <strong>Адрес: </strong>
      {{ data.address || '-' }}
    </p>

    <h2>Адрес проживания</h2>

    <p>
      <strong>Индекс: </strong>
      {{ data.homeZipCode || '-' }}
    </p>

    <p>
      <strong>Страна: </strong>
      {{ data.homeCountryRegion ? data.homeCountryRegion.name : '-' }}
    </p>

    <p v-if="data.isRusHomeCountryRegion">
      <strong>Регион: </strong>
      {{ data.homeState ? data.homeState.name : '-' }}
    </p>

    <p>
      <strong>Адрес: </strong>
      {{ data.homeAddress || '-' }}
    </p>

    <h2>Контакты</h2>

    <p>
      <strong>Мобильный телефон: </strong>
      {{ data.cellularPhone || '-' }}
    </p>

    <p>
      <strong>Домашний телефон: </strong>
      {{ data.homePhoneNumber || '-' }}
    </p>

    <p>
      <strong>Электронная почта: </strong>
      {{ data.email || '-' }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'ShowPersonalDataCard',

  props: {
    data: Object
  }
}
</script>

<style scoped>
.tab-content h2 {
  font-weight: 500;
  line-height: 1.1 !important;
  font-size: 19px !important;
  margin-top: 19px;
  margin-bottom: 19px;
  text-align: left;
  color: #37474f
}

.tab-content h2:first-child {
  margin-top: 0
}

.tab-content p {
  margin-bottom: 4px;
  font-size: 15px;
  color: #37474f
}
</style>