<template>
  <div>
    <div class="text-center" v-if="!dataLoaded">
      <v-progress-circular class="mt-6" :size="50" color="primary" indeterminate />
    </div>

    <div class="mx-4" v-else>
      <PageTitle title="Список слушателей" />

      <v-row class="my-0">
        <v-col cols="12" class="py-0">
          <h4 class="department">{{ departmentName }}</h4>
        </v-col>
      </v-row>

      <ListenersButtons
        :showFilter="showFilter"
        :cycleIdFromFilters="cycleIdFromFilters"
        :cyclePlanDistFromFilters="cyclePlanDistFromFilters"
        @setShowFilter="setShowFilter"
        @openAddListenerDialog="addDialog = true"
      />

      <Filters
        ref="listenerFilters"
        :showFilter="showFilter"
        :cyclesList="cyclesList"
        :cycle="cycle"
        @setFilters="setFilters"
      />

      <ListenersTable
        :tableLoading="tableLoading"
        :listenersList="listenersList.list"
        :chipsHeight="chipsHeight"
        :filtersHeight="filtersHeight"
        @tableScroll="tableScroll"
        @showListener="showListener"
        @removeListener="removeListener"
      />

      <AddListenerDialog
        :dialog="addDialog"
        @closeDialog="closeAddListenerDialog"
      />

      <ShowListenerDialog
        :dialog="showDialog"
        :listenerId="showListenerId"
        @closeDialog="closeShowListenerDialog"
      />
    </div>
  </div>
</template>

<script>
import { departmentApi, fileDpoCardApi } from '@/api'
import { mapGetters } from 'vuex'
import _debounce from 'lodash/debounce'
import _cloneDeep from 'lodash/cloneDeep'
import { Listeners } from '@/models'
import PageTitle from '@/components/ui/Title'
import Filters from '@/components/listeners/Filters'
import ListenersButtons from '@/components/listeners/Buttons'
import ListenersTable from '@/components/listeners/Table'
import AddListenerDialog from '@/components/listeners/dialogs/AddListener'
import ShowListenerDialog from '@/components/listeners/dialogs/ShowListener'

export default {
  name: 'Listeners',

  metaInfo: {
    title: 'Список слушателей'
  },

  props: {
    cycle: Object
  },

  components: {
    PageTitle,
    Filters,
    ListenersButtons,
    ListenersTable,
    AddListenerDialog,
    ShowListenerDialog
  },

  async created() {
    await this.$store.dispatch('user/getCurrent')
    await this.$store.dispatch('department/checkDepartmentInLocalStorage')
    await this.getCyclesByDepartmentId()
    this.dataLoaded = true
    this.setFilterElementsHeight()
    this.setDefaultFilters()
    await this.getListeners()
  },

  mounted() {
    this.hideHtmlOverflow()
  },

  destroyed() {
    this.showHtmlOverflow()
  },

  data: () => ({
    dataLoaded: false,

    pageNumber: 0,
    lastPage: false,
    total: 0,
    tableLoading: false,
    showFilter: false,
    chipsHeight: 0,
    filtersHeight: 0,
    addDialog: false,
    showDialog: false,
    showListenerId: null,
    cyclesList: [],

    listenersList: new Listeners(),
    filters: {}
  }),

  computed: {
    ...mapGetters('user', ['isSuperAdmin']),
    ...mapGetters('department', ['departmentId', 'departmentName']),

    cycleIdFromFilters() {
      return this.filters?.cycle?.cycleId || null
    },

    cyclePlanDistFromFilters() {
      if (this.filters?.cycle?.planDist === undefined) return true

      return this.filters?.cycle?.planDist
    }
  },

  methods: {
    setDefaultFilters() {
      if (this.cycle) {
        this.filters.cycle = this.cycle || null
      } else {
        this.getFiltersFromLocalStorage()
      }
    },

    getFiltersFromLocalStorage() {
      const filters = localStorage.getItem('listenersFilters')

      if (filters) {
        this.filters = JSON.parse(filters)
      }
    },

    async getCyclesByDepartmentId() {
      if (this.isSuperAdmin) {
        const cyclesList = await departmentApi.getCyclesAllTimeByDepartmentId(this.departmentId)

        this.cyclesList = cyclesList.map(cycle => {
          return {
            ...cycle,
            cycleNum: `${cycle.cycleNum} (${cycle.academyYearId})`
          }
        })
      } else {
        this.cyclesList = await departmentApi.getCyclesByDepartmentId(this.departmentId)
      }
    },

    async getListeners() {
      try {
        if (!this.lastPage) {
          const filters = {
            fullName: this.filters.fullName || null,
            snils: this.filters.snils || null,
            cycleId: this.filters.cycle?.cycleId || null
          }

          this.tableLoading = true
          const { content, last, totalElements } = await fileDpoCardApi.getListeners(this.departmentId, this.pageNumber, filters)
          this.pageNumber === 0 && this.listenersList.clearAll()
          this.lastPage = last
          this.total = totalElements
          this.pageNumber++
          content?.length && content.forEach(obj => this.listenersList.addListener(obj))
        }
      } catch (e) {
        console.log(e.status)
      } finally {
        this.tableLoading = false
      }
    },

    showListener(id) {
      this.showListenerId = id
      this.showDialog = true
    },

    removeListener(id) {
      this.listenersList.removeListener(id)
      this.updateCurrentListenersList()
    },

    async updateCurrentListenersList() {
      this.pageNumber--
      this.lastPage = false
      await this.getListeners()
    },

    setShowFilter() {
      this.showFilter = !this.showFilter
      this.setFilterElementsHeight()
    },

    setFilterElementsHeight() {
      this.$nextTick(() => {
        const chips = this.$refs.listenerFilters.$children[0]
        const filters = this.$refs.listenerFilters.$children[1]

        if (chips) this.chipsHeight = chips.$el.offsetHeight
        if (filters) this.filtersHeight = filters.$el.offsetHeight
      })
    },

    tableScroll(event) {
      const el = event.target
      if (el.scrollHeight - el.clientHeight - el.scrollTop <= 10 && !this.tableLoading) {
        this.getListeners()
      }
    },

    setFilters: _debounce(async function(filters) {
      this.filters = _cloneDeep(filters)

      this.pageNumber = 0
      this.lastPage = false
      this.total = 0
      await this.getListeners()
      this.setFilterElementsHeight()
    }, 500),

    closeAddListenerDialog() {
      this.addDialog = false
    },

    closeShowListenerDialog() {
      this.showListenerId = null
      this.showDialog = false
    },

    hideHtmlOverflow() {
      document.querySelector('html').style.setProperty('overflow-y', 'hidden', 'important')
    },

    showHtmlOverflow() {
      document.querySelector('html').style.overflowY = 'visible'
    }
  }
}
</script>