<template>
  <div>
    <v-card class="table-card" :class="chipsHeight ? 'mt-3' : 'mt-4'">
      <v-data-table
        v-scroll.self="tableScroll"
        class="base-table"
        ref="listenersTable"
        fixed-header
        hide-default-footer
        :loading="tableLoading"
        :headers="tableHeaders"
        :items="listenersList"
        :items-per-page="-1"
      >
        <template #item.index="{ item, index }">
          {{ index + 1 }}
        </template>

        <template #item.fullName="{ item }">
          <span>{{ item.fullName }}</span>
          <span v-if="item.status === false" class="draft" :class="{'pl-2': item.fullName}">
            (черновик)
          </span>
        </template>

        <template #item.actions="{ item }">
          <ReportMenu :fileDpoCardId="item.id" />

          <v-icon color="lightGrey" class="mr-1 show-btn" size="22" title="Просмотреть" @click="showListener(item.id)">
            mdi-eye-outline
          </v-icon>

          <v-icon color="lightGrey" class="mr-1 edit-btn" size="22" title="Редактировать" @click="editListener(item.id)">
            mdi-pencil-outline
          </v-icon>

          <v-icon color="lightGrey" class="remove-btn" size="22" title="Удалить" @click="confirmRemoveListener(item)">
            mdi-trash-can-outline
          </v-icon>
        </template>

        <template slot="loading">
          <div class="py-3">Загрузка...</div>
        </template>

        <template slot="no-data">
          <div class="py-3">Слушатели не найдены</div>
        </template>
      </v-data-table>
    </v-card>

    <DeleteConfirmDialog
      :dialog="confirmDialog"
      :loading="deleteLoading"
      :message="'Вы уверены, что хотите удалить слушателя?'"
      @confirm="removeListener"
      @closeConfirmModal="closeConfirmDialog"
    />
  </div>
</template>

<script>
import { fileDpoCardApi } from '@/api'
import { mapGetters } from 'vuex'
import ReportMenu from '@/components/listeners/ReportMenu'
import DeleteConfirmDialog from '@/components/dialogs/DeleteConfirm'

export default {
  name: "ListenersTable",

  components: {
    ReportMenu,
    DeleteConfirmDialog
  },

  props: {
    error: String,
    tableLoading: Boolean,
    chipsHeight: Number,
    filtersHeight: Number,
    listenersList: {
      type: Array,
      required: true
    }
  },

  mounted() {
    this.setTableHeight()
    window.addEventListener("resize", this.setTableHeight)
  },

  destroyed() {
    window.removeEventListener("resize", this.setTableHeight)
  },

  data: () => ({
    confirmDialog: false,
    deleteLoading: false,
    selectedItem: null,

    tableHeaders: [
      { text: '№ п/п', value: 'index', sortable: false, align: 'left' },
      { text: 'ФИО', value: 'fullName', sortable: false, align: 'left' },
      { text: 'Номер СНИЛС', value: 'snils', sortable: false, align: 'left' },
      { text: 'Номер цикла', value: 'cycleNum', sortable: false, align: 'center' },
      { text: 'Регион', value: 'state.name', sortable: false, align: 'left' },
      { text: 'Действия', value: 'actions', sortable: false, align: 'right', width: '160px' }
    ]
  }),

  computed: {
    ...mapGetters('system', ['techMessageHeight'])
  },

  methods: {
    setTableHeight() {
      const chipsHeight = this.chipsHeight ? this.chipsHeight + 12 : 16
      const filtersHeight = this.filtersHeight ? this.filtersHeight + 16 : 0
      // суммарная высота элементов на странице, кроме таблицы
      const otherElements = 282 + chipsHeight + filtersHeight + this.techMessageHeight

      if (this.$refs?.listenersTable) {
        this.$refs.listenersTable.$el.style.maxHeight = window.innerHeight - otherElements + 'px'
      }
    },

    tableScroll(event) {
      this.$emit('tableScroll', event)
    },

    showListener(id) {
      this.$emit('showListener', id)
    },

    editListener(id) {
      this.$router.push('/listeners/edit/' + id)
    },

    async removeListener() {
      try {
        if (this.selectedItem.id) {
          this.deleteLoading = true

          await fileDpoCardApi.removeListenerCard(this.selectedItem.id)
          this.$emit('removeListener', this.selectedItem.id)
          this.closeConfirmDialog()
        }
      } catch (e) {
        this.showErrorMessage(e, 'Ошибка удаления слушателя')
      } finally {
        this.deleteLoading = false
      }
    },

    confirmRemoveListener(item) {
      this.selectedItem = item
      this.confirmDialog = true
    },

    closeConfirmDialog() {
      this.confirmDialog = false
      this.selectedItem = null
    }
  },

  watch: {
    chipsHeight() {
      this.setTableHeight()
    },

    filtersHeight() {
      this.setTableHeight()
    }
  }
}
</script>

<style scoped>
.base-table span.draft {
  font-size: 13px;
  color: #81939c;
}
</style>